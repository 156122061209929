import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const WhySwitchSolar = () => {
  return (
    <div className="container text-center">
          <h2 className="text-6xl mb-12">Why switch to solar?</h2>
          <div className="lg:flex lg:space-x-16">

            <div className="lg:w-1/3">
              <StaticImage src="../../images/general/pv-wind-sunrise.jpg" width={150} height={150} className="mb-6 rounded-full" alt="reduce carbon" />
              <h3 className="text-primary text-4xl mb-4">Reduce carbon emissions</h3>
              <p>The Paris Agreement requires a vast reduction in carbon emissions. Help your organisation do its bit by embracing solar energy.</p>
            </div>

            <div className="lg:w-1/3">
              <StaticImage src="../../images/backgrounds/rooftop-solar.jpg" width={150} height={150} className="mb-6 rounded-full" alt="reduce carbon" />
              <h3 className="text-primary text-4xl mb-4">Cut the cost of electricity bills</h3>
              <p>If you are a large power user then every kilowatt you produce yourself is a kilowatt you don’t have to buy from a utility. Move to self-generation and enjoy the benefit of lower bills.</p>
            </div>

            <div className="lg:w-1/3">
              <StaticImage src="../../images/general/drivein-pv+ev-station.jpg" width={150} height={150} className="mb-6 rounded-full" alt="reduce carbon" />
              <h3 className="text-primary text-4xl mb-4">Prepare for electric vehicles</h3>
              <p>Electric vehicle (EV) ownership is soaring as nations aim to phase out internal combustion engines. Make sure you have the charging capacity on hand to serve this move to cleaner mobility.</p>
            </div>

          </div>

        </div>
  )
}
export default WhySwitchSolar
