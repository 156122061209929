import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ButtonPrimaryArrow from "components/buttons/ButtonPrimaryArrow"

export default function LatestArticles() {
  const data = useStaticQuery(graphql`
    {
      allNodeArticle(
        filter: {
          relationships: {
            field_article_destination: {
              elemMatch: { name: { eq: "Solar PV" } }
            }
          }
        }
        sort: { fields: [created], order: DESC }
        limit: 3
      ) {
        edges {
          node {
            id
            title
            field_teaser {
              value
              processed
            }
            created(formatString: "DD MMMM, YYYY")
            path {
              alias
            }
            relationships {
              field_image {
                relationships {
                  field_media_image_1 {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 90
                          width: 600
                          aspectRatio: 1.8
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const latestArticles = data.allNodeArticle.edges
  return (
    <div className="container">
      <div className="mb-4 text-center">
        <div className="section-header mb-2">Insights /</div>
        <h2 className="text-4xl">Latest</h2>
      </div>

      <div className="lg:flex lg:justify-between lg:space-x-6 xl:space-x-12">
        {latestArticles.map((item, key) => {
          return (
            <div
                key={key}
                className="w-4/12">
                <div className="border border-gray-200 shadow-sm h-full rounded rounded-bl-none">
                  {item.node.relationships.field_image.relationships
                    .field_media_image_1 && (
                      <Link to={item.node.path.alias}>
                        <GatsbyImage
                          image={getImage(
                            item.node.relationships.field_image.relationships
                              .field_media_image_1.localFile
                          )}
                          alt="view article"
                          className="w-full mb-1 rounded-t"
                        />
                      </Link>
                    )}
                  <div className="p-6">
                    <Link to={item.node.path.alias}>
                      <h3 className="text-xl mb-3">{item.node.title}</h3>
                    </Link>
                    <div
                      className="text-listing text-sm line-clamp-3"
                      dangerouslySetInnerHTML={{
                        __html: item.node.field_teaser.value,
                      }}
                    />
                  </div>
                </div>
              </div>
          )
        })}
      </div>

      <div className="flex justify-center pt-12">
      <ButtonPrimaryArrow title="View all articles" url="articles" />
      </div>

      

    </div>
  )
}
