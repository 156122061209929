import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

//page building utils
import ImgBackground from "components/heroes/ImgBackground"
import TitleBox from "components/titles/Titlebox"
import ButtonPrimaryArrow from "components/buttons/ButtonPrimaryArrow"
import VimeoEmbed from "components/videos/video-vimeo"

// content blocks
import WhySwitchSolar from "components/blocks/WhySwitchSolar"
import LatestArticles from "components/blocks/LatestArticles"

import { ArrowRightIcon } from '@heroicons/react/solid'

// set links for hero mini nav
const heroNav = [
  { name: "Utility Scale Solar", href: "/utility-scale" },
  { name: "Rooftop Solar", href: "/rooftop" },
  { name: "Carpark & Canopies", href: "#" },
]

const IndexPage = ({ data }) => {
  
  return (
    <Layout>
      <Seo title="Home" />

      {/* Hero Banner */}
      <ImgBackground image={data.mainhero}>
        <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-80 lg:flex">
          <TitleBox
            caption="Plug into the sun"
            title="Use Photovoltaic Solar to harness unlimited clean energy and new business opportunities"
          />

          <div className="flex items-end lg:w-1/3">
            <div className="w-full flex flex-col space-y-4 m-4">
              {heroNav.map((item, key) => (
                <Link key={key} to={item.href} className="flex justify-between items-center bg-primary-500 rounded-br-lg text-white text-2xl xl:text-3xl p-4 py-6">
                  {item.name}<ArrowRightIcon className="h-5 w-5 text-white" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </ImgBackground>


      {/* intro panel */}
      <section className="py-16">
        <div className="container">

          <h2 className="font-medium text-4xl mb-12 mt-4 text-center lg:mt-12">The Pacific Green PV Technologies team has been involved in the delivery of the largest solar carports in the UK and is part of a major clean technology services conglomerate serving clients worldwide.</h2>

          <div className="text-center md:flex md:flex-wrap md:-mx-4 justify-center">
            <div className="md:w-1/2 md:px-4 lg:w-1/3 mb-8">
            <Link to="/utility-scale"><StaticImage src="../images/backgrounds/utility-scale-fields.jpg" aspectRatio="2.1" className="mb-6 rounded-bl-xl" alt="utility scale solar installation" /></Link>
              <Link to="/utility-scale"><h3 className="text-primary-500 mb-2">Utility Scale</h3></Link>
              <p>Take advantage of fallow and brownfield sites to create new electricity savings and revenue streams from PV generation.</p>
            </div>

            <div className="md:w-1/2 md:px-4 lg:w-1/3 mb-8">
            <Link to="/rooftop"><StaticImage src="../images/backgrounds/rooftop-solar.jpg" aspectRatio="2.1" className="mb-6 rounded-bl-xl" alt="utility scale solar installation" /></Link>
            <Link to="/rooftop"><h3 className="text-primary-500 mb-2">Rooftop</h3></Link>
              <p>Turn your office, factory or warehouse roof into a solar generation plant and reap the benefits of a cheaper, cleaner and more stable energy supply.</p>
            </div>

            <div className="md:w-1/2 md:px-4 lg:w-1/3 mb-8">
            <Link to="/canopies"><StaticImage src="../images/backgrounds/solar-canopy.jpg" aspectRatio="2.1" className="mb-6 rounded-bl-xl" alt="utility scale solar installation" /></Link>
            <Link to="/canopies"><h3 className="text-primary-500 mb-2">Carparks & Canopies</h3></Link>
              <p>Prepare for the phaseout of fossil-fueled vehicles with a solar carport that replaces costly, polluting petrol and diesel with free electrons from the sun.</p>
            </div>
          </div>
        </div>
      </section>

      {/* service overview section */}
      <section className="bg-blue-50 py-16">
        <div className="container">

          <h2 className="mb-8 text-center">
            All the ingredients you need
          </h2>

          

          <div className="lg:flex lg:space-x-12">

            <div className="mb-12 lg:w-1/2 2xl:w-7/12 lg:mb-0">
              <VimeoEmbed src="https://player.vimeo.com/video/669399313" title="Pacific Green Solar PV" />
              <h4 className="mt-8">
                The complete service
              </h4>
              <ul className="mt-4 mb-12 flex flex-wrap">
                <li className="text-white bg-navy p-2 py-1 rounded-md mr-2 mb-2">Consultation</li>
                <li className="text-white bg-navy p-2 py-1 rounded-md mr-2 mb-2">Design</li>
                <li className="text-white bg-navy p-2 py-1 rounded-md mr-2 mb-2">Procurement</li>
                <li className="text-white bg-navy p-2 py-1 rounded-md mr-2 mb-2">Installation</li>
                <li className="text-white bg-navy p-2 py-1 rounded-md mr-2 mb-2">Commissioning</li>
                <li className="text-white bg-navy p-2 py-1 rounded-md mr-2 mb-2">Maintainance</li>
              </ul>

            </div>

            <div className="mb-12 lg:w-1/2 2xl:w-5/12 lg:mb-0">

              <h3 className="text-primary">Solar Systems</h3>
              <p className="text-sm">We work with only top-tier crystalline silicon module providers that offer you guaranteed performance at a competitive price. All modules come with a 12-year equipment warranty and a guarantee of 90% production after 10 years.</p>

              <h3 className="text-primary mt-8">Battery packs</h3>
              <p className="text-sm">Batteries allow you to get even greater value from your solar installation. Designed, installed and managed by our partner Pacific Green Energy Storage Technologies, our commercial and industrial-scale battery systems give you guaranteed performance with proven battery technology.</p>

              <h3 className="text-primary mt-8">EV charging</h3>
              <p className="text-sm mb-8">Whatever system design works best for your business, we will take care of all balance of plant elements including mounting, DC switches, inverters, meters, charge controllers and, if needed, electric vehicle charging infrastructure.</p>


              <ButtonPrimaryArrow title="About Us" url="/about-us" />


            </div>

          </div>

          
        </div>
      </section>

      {/* why switch section */}
      <section className="py-32 border-b border-gray-200">
        <WhySwitchSolar />
      </section>

      {/* Latest articles */}
      <section className="border-t border-gray-50 py-16">
        <LatestArticles />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    mainhero: file(
      relativePath: { eq: "backgrounds/utility-scale-fields.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
  }
`

export default IndexPage
